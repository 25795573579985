import { Config } from "config/config.type"
/** @firescoutMockVar Config.config */
const config:Config = {
  "env": "prod",
  "location": "lusini_da-dk",
  "shopName": "lusini",
  "locale": "da-dk",
  "rootCategoryId": "EMG",
  "links": {
    "contact": "/kundeservice/kontakt-os/"
  },
  "i18n": {
    "currency_ISO": "DKK",
    "locale": "da-DK",
    "country": "DK"
  },
  "availableCreditCards": [
    "MASTERCARD",
    "VISA"
  ],
  "orderMinimumB2C": 400,
  "slugifyLocale": "da",
  "hrefLang": [
    {
      "configFolder": "lusini",
      "locale": "da-dk",
      "hrefLang": "da"
    }
  ],
  "baseUrl": "https://www.lusini.com",
  "modules": {
    "onetrust": {
      "dataDomainScript": "bda0c016-94be-4fd9-943a-b9c09791a327"
    },
    "buzdev": {
      "errorUrl": "https://buz.lusini.com/webhook/com.lusini.commerce-platform/error/1-0-1"
    },
    "cloudinary": {
      "endpoint": "https://res.cloudinary.com/lusini/",
      "transformations": {
        "freisteller_l": "w_1500,h_1500,q_80,c_pad,f_auto",
        "freisteller_l_2": "w_1500,h_1500,q_80,c_pad,f_auto",
        "milieu_l": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "milieu_l_2": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "freisteller_m": "w_500,h_500,q_70,c_pad,f_auto",
        "freisteller_m_2": "w_500,h_500,q_70,c_pad,f_auto",
        "milieu_m": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "milieu_m_2": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "freisteller_s": "w_300,h_300,q_70,c_pad,f_auto",
        "freisteller_s_2": "w_600,h_600,q_70,c_pad,f_auto",
        "milieu_s": "w_300,h_300,g_auto,q_70,c_fill,f_auto",
        "milieu_s_2": "w_600,h_600,g_auto,q_70,c_fill,f_auto",
        "freisteller_xs": "w_50,h_50,q_50,c_pad,fl_lossy,f_auto",
        "freisteller_xs_2": "w_100,h_100,q_50,c_pad,fl_lossy,f_auto",
        "milieu_xs": "w_50,h_50,g_auto,q_50,c_fill,fl_lossy,f_auto",
        "milieu_xs_2": "w_100,h_100,g_auto,q_50,c_fill,fl_lossy,f_auto"
      },
      "fallback_product_picture": "https://res.cloudinary.com/lusini/image/upload/v1643182695/application/products/lusini-online-shop-platzhalter-produktbild-in-kuerze-da.svg"
    },
    "shopware": {
      "salutations": [],
      "initialSaluationId": "6d0b33d96b5b400cbabc648c0a4c3f60",
      "accessKey": {
        "b2b": "SWSCR1KKH3ZH64EEGANYSQ2F6I",
        "b2c": "SWSC7LQLXG5IPNH9HBNOBYLW02"
      },
      "storefrontUrl": {
        "b2b": "https://www.lusini.com/da-dk/b2b",
        "b2c": "https://www.lusini.com/da-dk/b2c"
      },
      "apiUrl": "https://shop-api.lusini.com/store-api"
    },
    "algolia": {
      "apiKey": "bf4bf9193d5f34cd369647376521dbc8",
      "applicationId": "WAVYBEW6OQ"
    },
    "contentful": {
      "space": "aza65graowyr",
      "environment": "master",
      "accessToken": "OExq-bY8Ujs7M06rQEL1drC2kfafk-qmEzA8DZO0CCE",
      "previewToken": "QP7JxRhc0mYWGrX3I8vj9WdBO_eF7n9r0xnmcX11HAw"
    },
    "dyHybrid": {
      "apiKey": "a72829a6281955d5f8febc70eeb0a8de8a9f4841db410f4012d1fb4bf2dc5ffa",
      "campaignID": "9879544"
    },
    "trustShop": {
      "placeholderImg": "https://res.cloudinary.com/lusini/image/upload/c_scale,w_100/v1670412947/application/icons/e-maerket-01.svg",
      "id": "cf9e465a43bc578ef3cc6cd9be55a8de",
      "url": "https://widget.emaerket.dk/js/",
      "urlPostfix": ""
    },
    "zenloop": {
      "id": "WlRNd05XWTJNREF0TWpoaU5DMDBOMk01TFdGbE5EY3RPVGM1TWpkall6Um1ZbUpp"
    },
    "hotjar": {
      "id": 3549008,
      "version": 6
    }
  },
  "index": {
    "products": "prod_lusini_da_DK_products",
    "productsPriceAsc": "prod_lusini_da_DK_products_priceAsc",
    "categories": "prod_lusini_da_DK_categories",
    "series": "prod_lusini_da_DK_series",
    "productsSuggestion": "prod_lusini_da_DK_products_query_suggestions",
    "productsLatest": "prod_lusini_da_DK_products_noveltiesAsc",
    "productReviews": "prod_lusini_product_reviews"
  },
  "deliveryDate": {
    "shippingTooLong": 200
  },
  "colors": {
    "Hvid": "white",
    "Sort": "black",
    "Grå": "grey",
    "Sølvfarve": "silver",
    "Brun": "brown",
    "Blå": "blue",
    "Flerfarvet": "multicolor",
    "Rød": "red",
    "Beige": "beige",
    "Grøn": "green",
    "Transparent": "transparent",
    "Gul": "yellow",
    "Guldfarve": "gold",
    "Orange": "orange",
    "Rosa/lilla": "lilac"
  },
  "features": {
    "creditCheck": true,
    "devMode": false,
    "trustShop": true,
    "requiresTerms": true,
    "zenloop": true,
    "b2c": true,
    "userLikeWidgetUrl": "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/b98b921b64b94ef4851e19255803b2f28c77bac3788f47689c7b1ae838b4eca3.js",
    "customizableUrl": "https://www.lusini.com/da-dk/kundeservice/service/profilering/",
    "notificationBar": true
  },
  "formApi": {
    "conformityDeclaration": "https://getform.io/f/d182a8a1-8e5a-41b4-a48a-cc31bf98cd16"
  },
  "abTests": {}
}
export default config